import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import PortableText from '../components/PortableText'

export const query = graphql`
  {
    sanityContactPage {
      pageTitle
      _rawBody
    }
  }
`

export default function Contact({ data: { sanityContactPage } }) {
  return (
    <Layout title='Contact' page='contact' url='/contact'>
      <h1 className='font-h1'>{sanityContactPage.pageTitle}</h1>
      <PortableText blocks={sanityContactPage._rawBody} />
    </Layout>
  )
}
